@font-face {
font-family: 'Gotham Narrow Bold';
font-style: normal;
font-weight: normal;
src: local('Gotham Narrow Bold'), url('GothamNarrow-Bold.otf') format('opentype');
font-display: swap;
}

@font-face {
font-family: 'Gotham Narrow Book';
font-style: normal;
font-weight: normal;
src: local('Gotham Narrow Book'), url('GothamNarrow-Book.otf') format('opentype');
font-display: swap;
}

@font-face {
font-family: 'Gotham Narrow Medium';
font-style: normal;
font-weight: normal;
src: local('Gotham Narrow Medium'), url('GothamNarrow-Medium.otf') format('opentype');
font-display: swap;
}

@font-face {
    font-family: "Gotham Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Gotham Medium"), url('Gotham-Medium.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: "Gotham Book";
    font-style: normal;
    font-weight: normal;
    src: local("Gotham Book"), url('Gotham-Book.otf') format('opentype');
    font-display: swap;
}
