@import url(https://use.typekit.net/jmd5kjg.css);
@import url(https://api.mapbox.com/mapbox-gl-js/v1.7.0/mapbox-gl.css);
@font-face {
font-family: 'Gotham Narrow Bold';
font-style: normal;
font-weight: normal;
src: local('Gotham Narrow Bold'), url(https://ajanvaraus.coronaria.fi/wp-content/avapp/build/static/media/GothamNarrow-Bold.3f7d2101.otf) format('opentype');
font-display: swap;
}

@font-face {
font-family: 'Gotham Narrow Book';
font-style: normal;
font-weight: normal;
src: local('Gotham Narrow Book'), url(https://ajanvaraus.coronaria.fi/wp-content/avapp/build/static/media/GothamNarrow-Book.57e24e78.otf) format('opentype');
font-display: swap;
}

@font-face {
font-family: 'Gotham Narrow Medium';
font-style: normal;
font-weight: normal;
src: local('Gotham Narrow Medium'), url(https://ajanvaraus.coronaria.fi/wp-content/avapp/build/static/media/GothamNarrow-Medium.506a45c4.otf) format('opentype');
font-display: swap;
}

@font-face {
    font-family: "Gotham Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Gotham Medium"), url(https://ajanvaraus.coronaria.fi/wp-content/avapp/build/static/media/Gotham-Medium.64243e21.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: "Gotham Book";
    font-style: normal;
    font-weight: normal;
    src: local("Gotham Book"), url(https://ajanvaraus.coronaria.fi/wp-content/avapp/build/static/media/Gotham-Book.b6e803be.otf) format('opentype');
    font-display: swap;
}



